@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700;800&display=swap');

:root {
    --color-primary: #7380ec;
    --color-danger: #ff7782;
    --color-success: #41f1b6;
    --color-warning: #ffbb55;
    --color-white: #fff;
    --color-info: #7d8da1;
    --color-dark: #363949;
    --color-light: rgba(132, 139, 200, 0.18);
    --color-dark-varient: #677483;
    --color-background: #f6f6f9;

    --card-border-radius: 2rem;
    --border-radius-1: 0.4rem;
    --border-radius-2: 1.2rem;

    --card-padding: 1.8rem;
    --box-shadow: 0 2rem 3rem var(--color-light)
}

.dark-theme {
    --color-background: #181a1e;
    --color-white: #202528;
    --color-dark: #edeffd;
    --color-dark-varient: #a3bdcc;
    --color-light: rgba(0, 0, 0, 0.4);
    --box-shadow: 0 2rem 3rem var(--color-light)
}


* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html {
    font-size: 14px;
    scroll-behavior: smooth;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: .88rem;
    background: var(--color-background);
    user-select: none;
    overflow-x: hidden;
    color: var(--color-dark);
}

* {
    color: var(--color-dark);
}

img {
    display: block;
    width: 100%;
}

h1 {
    font-weight: 800;
    font-size: 1.8rem;
}

h2 {
    font-size: 1.4rem;
}

h3 {
    font-size: .87rem;
}

h4 {
    font-size: .8rem;
}

h5 {
    font-size: .77rem;
}

small {
    font-size: .75rem;
}

.text-muted {
    color: var(--color-info);
}

p {
    color: var(--color-dark-varient);
}

b {
    color: var(--color-dark);
}

.primary {
    color: var(--color-primary);
}

.danger {
    color: var(--color-danger);
}

.success {
    color: var(--color-success)
}

.warning {
    color: var(--color-warning);
}

.container {
    position: relative;
    display: grid;
    gap: 1rem;
    grid-template-columns: 14rem auto 23rem;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto; 
    padding: 0 15px;
}

header h3 {
    font-weight: 500;
}

header {
    position: fixed;
    width: 100vw;
    z-index: 1000;
    background-color: var(--color-background);
}

header.active {
    box-shadow: var(--box-shadow);
}

header .logo {
    display: flex;
    gap: .8rem;
    margin-right: auto;
}

header .logo img {
    width: 2rem;
    height: 2rem;
}

header,
header .navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--color-info);
}

header .navbar a {
    display: flex;
    margin-left: 2rem;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 3.7rem;
    transition: all 300ms ease;
    padding: 0 2rem;
}

header .navbar a:hover {
    padding-top: 1.5rem;
}

header .navbar a.active {
    background: var(--color-light);
    color: var(--color-primary);
}

header .navbar a.active::before {
    content: "";
    background-color: var(--color-primary);
    position: absolute;
    height: 5px;
    width: 100%;
    left: 0;
    top: 0;
}

header #profile-btn {
    display: none;
    font-size: 2rem;
    margin: .5rem 2rem 0 0;
    cursor: pointer;
}

header .theme-toggler {
    background: var(--color-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.6rem;
    width: 4.2rem;
    cursor: pointer;
    border-radius: var(--border-radius-1);
    margin-right: 2rem;
}

header .theme-toggler span {
    font-size: 1.2rem;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

header .theme-toggler span.active {
    background-color: var(--color-primary);
    color: white;
    border-radius: var(--border-radius-1);
}

/* Profile section  */
aside .profile {
    margin-top: 2rem;
    width: 13rem;
    position: fixed;
}

aside .profile .top {
    display: flex;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid var(--color-light);
    padding-bottom: 1rem;
}

aside .profile .top:hover .profile-photo {
    scale: 1.05;
}

aside .profile .top .profile-photo {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    overflow: hidden;
    border: 5px solid var(--color-light);
    transition: all 400ms ease;
}

aside .profile .about p {
    padding-bottom: 1rem;
}

aside .profile .about {
    margin-top: 1rem;
}

/* Home Section  */
main {
    position: relative;
    margin-top: 1.4rem;
}

main .home_cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.6rem;
}

main .home_cards>div {
    background-color: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    margin-top: 1rem;
    box-shadow: var(--box-shadow);
    transition: all 300ms ease;
}

main .home_cards>div:hover {
    box-shadow: none;
}

main .home_cards>div span {
    background-color: var(--color-primary);
    padding: .5rem;
    border-radius: 50%;
    color: var(--color-white);
    font-size: 1.5rem;
}

main .home_cards>div.mth span,
main .home_cards>div.cg span {
    background: var(--color-danger);
}

main .home_cards>div.cs span {
    background: var(--color-success);
}

main .home_cards h3 {
    margin: 1rem 0 0.6rem;
    font-size: 1rem;
}

main .home_cards .progress {
    position: relative;
    width: 89px;
    height: 89px;
    border-radius: 50%;
    margin: auto;
}

main .home_cards svg circle {
    fill: none;
    stroke: var(--color-primary);
    stroke-width: 8;
    stroke-linecap: round;
    transform: translate(5px, 5px);
    stroke-dasharray: 110;
    stroke-dashoffset: 92;
}

main .home_cards .eg svg circle {
    stroke-dashoffset: 25;
    stroke-dasharray: 210;
}

main .home_cards .mth svg circle {
    stroke-dashoffset: 7;
    stroke-dasharray: 210;
}

main .home_cards .cs svg circle {
    stroke-dashoffset: 35;
    stroke-dasharray: 210;
}

main .home_cards .cg svg circle {
    stroke-dashoffset: 0;
    stroke-dasharray: 210;
}

main .home_cards .net svg circle {
    stroke-dashoffset: 5;
    stroke-dasharray: 210;
}

main .home_cards .progress .number {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

main .home_cards small {
    margin-top: 1rem;
    display: block;
}

/*Small Button*/
.small_button {
    display: inline-block;
    outline: none;
    border-width: 0px;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: inherit;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    background: #6370d7;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    padding: 0px 12px;
    vertical-align: middle;
    width: auto;
    font-size: 14px;
    color: rgb(255, 255, 255);
}

.small_button:hover {
    background: rgb(0, 101, 255);
    text-decoration: inherit;
    transition-duration: 0s, 0.15s;
    color: rgb(255, 255, 255);
}


/* Right  */
.right {
    margin-top: 1.5rem;
    padding-left: 1.5rem;
}

.right .announcements h2 {
    margin-bottom: .8rem;
}

.right .announcements .updates {
    background-color: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
    transition: all 300ms ease;
}

.right .announcements .updates:hover {
    box-shadow: none;
}

.right .announcements .updates .message {
    gap: 1rem;
    line-height: 1.5;
    padding: .5rem 0;
}

/* Users list */
.right .leaves {
    margin-top: 2rem;
}

.right .leaves h2 {
    margin-bottom: .8rem;
}

.right .leaves .teacher {
    background: var(--color-white);
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: .7rem;
    padding: 1.4rem var(--card-padding);
    border-radius: var(--border-radius-2);
    transition: all 300ms ease;
    box-shadow: var(--box-shadow);
}

.right .profile-photo {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    overflow: hidden;
}

/*Subission*/
header {
    position: relative;
}

.submission-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
}

.submission-container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: var(--border-radius-2);
    padding: 3.5rem;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    width: 95%;
    max-width: 50rem;
}

.submission-container form:hover {
    box-shadow: none;
}

.submission-container form input[type=password] {
    border: none;
    outline: none;
    border: 1px solid var(--color-light);
    background: transparent;
    height: 2rem;
    width: 100%;
    padding: 0 .5rem;
}

.submission-container form input[type=text] {
    border: none;
    outline: none;
    border: 1px solid var(--color-light);
    background: transparent;
    height: 2rem;
    width: 100%;
    padding: 0 .5rem;
}

.reviewer_form select {
    border: none;
    outline: none;
    border: 1px solid black;
    background: transparent;
    height: 2rem;
    width: 100%;
    padding: 0 .5rem;
    margin-bottom: 5px;
}

.reviewer_form input[type=text] {
    border: none;
    outline: none;
    border: 1px solid black;
    background: transparent;
    height: 2rem;
    width: 100%;
    padding: 0 .5rem;
    margin-bottom: 5px;
}

.reviewer_form input[type=number] {
    border: none;
    outline: none;
    border: 1px solid black;
    background: transparent;
    height: 2rem;
    width: 100%;
    padding: 0 .5rem;
    margin-bottom: 5px;
}

.reviewer_form input[type=password] {
    border: none;
    outline: none;
    border: 1px solid black;
    background: transparent;
    height: 2rem;
    width: 100%;
    padding: 0 .5rem;
    margin-bottom: 5px;
}

.reviewer_form textarea {
    border: none;
    outline: none;
    border: 1px solid black;
    background: transparent;
    height: 2rem;
    width: 100%;
    padding: 0 .5rem;
    margin-bottom: 5px;
}

.submission-container form input[type=date] {
    border: none;
    outline: none;
    border: 1px solid var(--color-light);
    background: transparent;
    height: 2rem;
    width: 100%;
    padding: 0 .5rem;
}

.submission-container form textarea {
    border: none;
    outline: none;
    border: 1px solid var(--color-light);
    background: transparent;
    height: 2rem;
    width: 100%;
    padding: 0 .5rem;
}

.submission-container form select {
    border: none;
    outline: none;
    border: 1px solid var(--color-light);
    background: transparent;
    height: 2rem;
    width: 100%;
    padding: 0 .5rem;
}

.submission-container form .box {
    padding: .5rem 0;
}

.submission-container form .box p {
    line-height: 2;
}

.submission-container form h2+p {
    margin: .4rem 0 1.2rem 0;
}

.conference_form select {
    border: none;
    outline: none;
    border: 1px solid black;
    background: transparent;
    height: 2rem;
    /* width: 100%; */
    padding: 0 .5rem;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 10px;
}

.conference_form input[type=text] {
    border: none;
    outline: none;
    border: 1px solid black;
    background: transparent;
    height: 2rem;
    /* width: 100%; */
    padding: 0 .5rem;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 10px;
}

.conference_form input[type=number] {
    border: none;
    outline: none;
    border: 1px solid black;
    background: transparent;
    height: 2rem;
    /* width: 100%; */
    padding: 0 .5rem;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 10px;
}

.conference_form input[type=date] {
    border: none;
    outline: none;
    border: 1px solid black;
    background: transparent;
    height: 2rem;
    /* width: 100%; */
    padding: 0 .5rem;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 10px;
}

.conference_form textarea {
    border: none;
    outline: none;
    border: 1px solid black;
    background: transparent;
    width: 95%;
    padding: 0 .5rem;
    margin-bottom: 5px;
    margin-top: 5px;
    /* margin-left: 5px; */
    margin-right: 2px;
}

.btn {
    background: none;
    border: none;
    border: 2px solid var(--color-primary) !important;
    border-radius: var(--border-radius-1);
    padding: .5rem 1rem;
    color: var(--color-white);
    background-color: var(--color-primary);
    cursor: pointer;
    margin: 1rem 1.5rem 1rem 0;
    margin-top: 1.5rem;
}

.btn:hover {
    color: var(--color-primary);
    background-color: transparent;
}

/*  Chat  */
  
.chat-messages {
	border-top: 1px solid rgba(0, 0, 0, .05);
	padding: 10px;
	max-height: 425px;
	overflow: auto;
	display: flex;
	flex-flow: row wrap;
	align-content: flex-start;
	flex: 1;
  }
  
  .chat-date-time
  {
	font-size: 9px;
	  font-weight: bold;
	  color: #3b4c5c;
  }
  
  .message-box-holder {
	width: 100%;
	margin: 0 0 15px;
	display: flex;
	flex-flow: column;
	align-items: flex-end;
  }
  
  .message-sender {
	font-size: 12px;
	margin: 0 0 15px;
	color: #30649c;
	align-self: flex-start;
  }
  
  .message-sender a, .message-sender a:link, .message-sender a:visited, .chat-partner-name a, .chat-partner-name a:link, .chat-partner-name a:visited {
	color: #30649c;
	text-decoration: none;
  }
  
  .message-box {
	padding: 6px 10px;
	border-radius: 6px 0 6px 0;
	position: relative;
	background: rgba(100, 170, 0, .1);
	border: 2px solid rgba(100, 170, 0, .1);
	color: #6c6c6c;
	font-size: 14px;
  }
  
  .message-box:after {
	content: "";
	position: absolute;
	border: 10px solid transparent;
	border-top: 10px solid rgba(100, 170, 0, .2);
	border-right: none;
	bottom: -22px;
	right: 10px;
  }
  
  .message-partner {
	background: rgba(0, 114, 135, .1);
	border: 2px solid rgba(0, 114, 135, .1);
	align-self: flex-start;
  }
  
  .message-partner:after {
	right: auto;
	bottom: auto;
	top: -22px;
	left: 9px;
	border: 10px solid transparent;
	border-bottom: 10px solid rgba(0, 114, 135, .2);
	border-left: none;
  }
  
  .chat-input-holder {
	display: flex;
	border-top: 1px solid rgba(0, 0, 0, .1);
  }
  
  .chat-input {
	resize: none;
	padding: 5px 10px;
	height: 40px;
	font-family: 'Lato', sans-serif;
	font-size: 14px;
	color: #999999;
	flex: 1;
	border: none;
	background: rgba(0, 0, 0, .05);
	 border-bottom: 1px solid rgba(0, 0, 0, .05);
  }
  
  .chat-input:focus, .message-send:focus {
	outline: none;
  }
  
  .message-send::-moz-focus-inner {
	border:0;
	padding:0;
  }
  
  .message-send {
	-webkit-appearance: none;
	background: #66a6cb;
  
	font-size: 12px;
	padding: 0 15px;
	border: none;
	color: white;
  }

/* MEDIA QUERIES  */
@media screen and (max-width: 1200px) {
    html {
        font-size: 12px;
    }

    .container {
        grid-template-columns: 13rem auto 20rem;
        max-width: 1200px; /* Adjust to your preferred max-width */
        width: 100%;       /* Ensures it fills the available width */
        margin: 0 auto;    /* This centers the container */
        padding: 0 15px;
    }

    header {
        position: fixed;
    }

    .container {
        padding-top: 4rem;
        max-width: 1200px; /* Adjust to your preferred max-width */
        width: 100%;       /* Ensures it fills the available width */
        margin: 0 auto;    /* This centers the container */
        padding: 0 15px;
    }

    header .logo h2 {
        display: none;
    }

    header .navbar h3 {
        display: none;
    }

    header .navbar a {
        width: 4.5rem;
        padding: 0 1rem;
    }

    main .home_cards {
        grid-template-columns: repeat(2, 1fr);
        gap: 1;
    }

}


@media screen and (max-width: 768px) {
    html {
        font-size: 10px;
    }

    header {
        padding: 0 .8rem;
    }

    .container {
        width: 100%;
        grid-template-columns: 1fr;
        margin: 0;
        max-width: 1200px; /* Adjust to your preferred max-width */
        width: 100%;       /* Ensures it fills the available width */
        margin: 0 auto;    /* This centers the container */
        padding: 0 15px;
    }

    header #profile-btn {
        display: inline;
    }

    header .navbar {
        padding: 0;
    }

    header .navbar a {
        margin: 0 .5rem 0 0;
    }

    header .theme-toggler {
        margin: 0;
    }

    aside {
        position: absolute;
        top: 4rem;
        left: 0;
        right: 0;
        background-color: var(--color-white);
        padding-left: 2rem;
        transform: translateX(-100%);
        z-index: 10;
        width: 18rem;
        height: 100%;
        box-shadow: 1rem 3rem 4rem var(--color-light);
        transition: all 2s ease;
    }

    aside.active {
        transform: translateX(0);
    }

    main {
        padding: 0 2rem;
    }

    .right {
        width: 100%;
        padding: 2rem;
    }
}

 